<template>
    <div class="OrderList">
    <!-- <GlobalInfoBar title="换新商品管理" content="管理和查看换新商品，包括创建、上架、下架商品" /> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <OrderFrom />
      </div>
      <div class="caseOrder">
          <OrderTable @getUnPayUserOrders="getUnPayUserOrders"/>
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import OrderFrom from "./Form"
import OrderTable from "./Table"
export default {
  name: "OrderList",
  components:{OrderFrom,OrderTable},
  data() {
    return {
      classList: null,
      merchantId: '',
      UnPayUserOrders: 0
    };
  },
  created() {},
  methods: {
    getUnPayUserOrders(e) {
      this.UnPayUserOrders = e
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.OrderList {
  .caseOrder {
        margin: 0 5px;
        // background: #f5f6fa;
        border-radius: 10px;
    }
}
</style>

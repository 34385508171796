<template>
  <div class="GlobalTable">
    <div class="button_top">串号查询明细</div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="关联订单" slot="contractType" align="center">
        <template slot-scope="{ row }">
          <span
          @click="goOrderDetail(row)"
          style="color: #0981ff; cursor: pointer; text-decoration: underline"
          >{{ row.orderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="机型"
        width="130px"
        slot="phoneName"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.phoneName||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="查询类型"
        width="130px"
        slot="contractDocument"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.detectionCategoryName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="查询结果" slot="merchantType" align="center">
        <template slot-scope="{ row }">
          <div
            style="color: #0981ff; cursor: pointer; text-decoration: underline"
          >
            <span @click="goodsDialog(row)">查看</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="查询渠道" slot="channel" align="center">
        <template slot-scope="{ row }">
          <div class="Remarks">
            <span>{{ row.queryChannel == "01" ? "回收端小程序" : "平台" }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="查询回收商"
        width="130px"
        slot="startTime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.merchantName ||"--"}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="查询人"
        width="130px"
        slot="addPeople"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle" v-if="row.queryChannel == '01'">
            <span>{{ row.quoterName }}-{{ row.quoterPhone }}</span>
            <el-tag v-if="row.positionType=='02'" type="warning" size="small" effect="dark">报价师</el-tag>
            <el-tag v-else type="success" size="small" effect="dark">{{ row.isMajor?'创建人':'子账号' }}</el-tag>
          </div>
          <div class="wrapStyle" v-else>
            <span>{{ row.useAdminName }}-{{ row.useAdminAcc }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="扣减收货次数"
        width="130px"
        slot="cancellationPeople"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
          @click="storeDialog(row)"
            style="color: #0981ff; cursor: pointer; text-decoration: underline"
          >
            <span>{{ row.onceTimes || 0 }}次</span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="检测保留时长（h）"
        width="130px"
        slot="cancellationTime"
        align="center"
      >
        <template slot-scope="{ row }">
          <div class="wrapStyle">
            <span>{{ row.cancelledTime || "--" }}</span>
          </div>
        </template>
      </el-table-column> -->
    </GlobalTable>
    <!-- 查询结果 -->
    <el-dialog
      title="查询结果"
      :visible.sync="goodsVisible"
      :close-on-click-modal="false"
      width="600px"
      @close="btnClose"
    >
     <div style="margin-bottom: 20px">
      <GlobalTable
        ref="GlobalTable"
        :loading="goodsLoading"
        :columns="goodsColumns"
        :data="dialogGoodsList"
        :isPagination="false"
        :maxHeight="625"
      >
        <el-table-column
          label="结果"
          slot="shopState"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.value ||"--"}}</span>
          </template>
        </el-table-column>
      </GlobalTable>
     </div>
    </el-dialog>
    <!-- 查看扣款明细 -->
    <el-dialog
      title="查看扣款明细"
      :visible.sync="storeVisible"
      :close-on-click-modal="false"
      width="700px"
      @close="btnClose"
    >
     <div style="margin-bottom: 20px;">
      <GlobalTable
        ref="GlobalTable"
        :loading="storeLoading"
        :columns="storeColumns"
        :data="dialogStoreList"
        :isPagination="false"
      >
      </GlobalTable>
     </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  data() {
    return {
      machineType: [],
      btnLoading: false,
      goodsVisible: false,
      storeVisible: false,
      goodsLoading: false,
      storeLoading: false,
      currentRow: {},
      dialogGoodsList: [],
      dialogStoreList: [],
      storePage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      goodsColumns: [
        { label: "查询内容", prop: "key" },
        { slotName: "shopState" },
      ],
      storeColumns: [
        { label: "扣除商家", prop: "merchantName" },
        { label: "扣款次数", prop: "onceTimes" },
        { label: "扣款时间", prop: "createTime" },
      ],
      productList: [],
      goodClassList: [],
      goodsStateList: [
        {
          label: "未上架",
          value: "00",
        },
        {
          label: "在售中",
          value: "03",
        },
      ],
      loading: false,
      seachDataList: [],
      command: "",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumns: [
        { label: "查询串号", prop: "imei" },
        { slotName: "contractType" }, //关联订单
        { slotName: "phoneName" },
        { label: "下单商户", prop: "companyName" },
        { label: "品牌", prop: "brandName" },
        { slotName: "contractDocument" }, //查询类型
        { slotName: "merchantType" }, //查询结果
        { slotName: "channel" }, //查询渠道
        { slotName: "startTime" }, //
        { slotName: "addPeople" }, //添加人
        { label: "查询时间", prop: "createTime" },
        { slotName: "cancellationPeople" }, //作废操作人
        { slotName: "cancellationTime" }, //作废时间
      ],
      shanpsSelectList: [],
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  created() {
    this.handleCurrentChange();
    // this.getAllMachineType();
    // this.productSelect();
  },
  watch: {
    SeachParams(newVal) {
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    //回收商名称下拉
    getAllMachineType() {
      _api
        .listSimpleMerchantView({
          isFilterMatrix: "01",
          isNice: "",
          pageNum: 1,
          pageSize: 999999,
        })
        .then((res) => {
          this.machineType = res.data;
        });
    },
    // 商品查询
    searchSettingGoods() {
      this.goodsLoading = true;
      _api.getqueryResults({id:this.currentRow.id}).then((res) => {
        if (res.code === 1) {
          this.dialogGoodsList = res.data;
          this.goodsLoading = false;
        }
      });
    },
    StoreSearch(val) {
      if (val) {
        this.storePage.pageNum = val;
      }
      this.searchSettingStore();
    },
    // 查询
    searchSettingStore() {
      this.storeLoading = true;
      _api.getqueryMerchants({id:this.currentRow.id}).then((res) => {
        if (res.code == 1) {
          this.dialogStoreList = res.data;
          this.storeLoading = false;
        }
      });
    },
    // 列表关闭
    btnClose() {
      this.storePage = {
        pageNum: 1,
        total: 0,
      };
    },
    goodsDialog(row) {
      this.currentRow = row;
      this.goodsVisible = true;
      this.searchSettingGoods();
    },
    //合同类型下拉
    productSelect() {
      _api.gettypeList().then((res) => {
        this.productList = res.data;
      });
    },
    // 商品所属分类下拉
    getGoodsClassSelectList() {
      _api.activityTypeSelect({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.goodClassList = res.data;
        }
      });
    },
    storeDialog(row) {
      this.currentRow = row;
      this.storeVisible = true;
      this.searchSettingStore();
    },
    // 跳转至订单详情
    goOrderDetail(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: {
          id: row.orderNo,
          type:'edit'
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log("列表查询");
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        imei: this.SeachParams.imei,
        brandNo: this.SeachParams.brandNo,
        detectionCategoryId: this.SeachParams.detectionCategoryId,
        queryChannel: this.SeachParams.queryChannel,
        merchantId: this.SeachParams.merchantId,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };

      this.loading = true;
      _api.gecategoryListRecord(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.center_book {
  padding: 5px 20px;
}

.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}
// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.GlobalTable {
  .wrapStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .staff-list-form {
    .el-input {
      width: 170px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .button_top {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .repair-conent {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .faultBtn {
    //width: 432px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .remarks-tip {
    color: #0981ff;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.call_accounts_info {
  margin: 10px 0;
}

.call_accounts_check {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.call_accounts_result {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.el_from {
  margin-left: 30px;

  .form_flex_cont {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }

  .drawerWith {
    width: 500px;
  }

  .timeStyle {
    margin-top: 15px;
  }
}

.tagStyle {
  border: 1px solid #0981ff;
  border-radius: 10px;
  padding: 3px 10px;
  color: #0981ff;
  margin-right: 10px;
}
.text-down {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}
</style>

<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="85"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        label-width="70px"
        label="查询渠道"
        slot="contractType"
        style="margin: 0"
      >
        <el-select
          :popper-append-to-body="false"
          v-model="queryChannel"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in goodsStateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from select_down"
        label="选择回收商"
        slot="signatoryId"
        style="margin: 0"
      >
        <el-select
          filterable
          :popper-append-to-body="false"
          v-model="merchantId"
          placeholder="请选择或输入回收商名称"
          clearable
        >
          <el-option
            v-for="item in machineType"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="70px"
        label="旧机品牌"
        slot="Oldmachinebrand"
        style="margin: 0"
      >
        <el-select
          :popper-append-to-body="false"
          v-model="brandNo"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.name"
            :value="item.brandNo"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="70px"
        label="查询类型"
        slot="signatoryName"
        style="margin: 0"
      >
        <el-select
          :popper-append-to-body="false"
          v-model="detectionCategoryId"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in detectionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  data() {
    return {
      merchantId: "",
      machineType: [],
      brandNo: "",
      detectionCategoryId: "",
      queryChannel: "",
      productList: [],
      detectionList: [],
      initData: {
        entryTime: [moment().day(moment().day()).format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59')]
      },
      goodsStateList: [
        {
          label: "回收端小程序",
          value: "01",
        },
        {
          label: "平台",
          value: "02",
        },
      ],
      startTime: "",
      endTime: "",
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 180 * 24 * 3600 * 1000,
      formItemList: [
        { slotName: "contractType" }, //
        { slotName: "signatoryId" }, //
        {
          key: "imei",
          type: "input",
          labelName: "串号",
          placeholder: "请输入串号查询",
          lableWidth: "50px",
        },
        { slotName: "Oldmachinebrand" }, //品牌
        { slotName: "signatoryName" }, //查询类型
        {
          key: "entryTime",
          type: "pickerOptions",
          clearable: true,
          labelName: "查询时间",
          lableWidth: "70px",
          placeholder: "请选择时间",
          valueFormat: "timestamp",

          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
      ],
      formRules: {
        mobile: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
      SeachParams: {},
    };
  },
  created() {
    this.getResidentList();
    this.getAllMachineType();
    this.getDetectionCategory();
    this.handleConfirm(this.initData)
  },
  methods: {
    // 获取旧机品牌列表
    getDetectionCategory() {
      _api.getDetectionCategory().then((res) => {
        this.detectionList = res.data;
      });
    },
    // 获取旧机品牌列表
    getResidentList() {
      _api.ResidentList().then((res) => {
        this.productList = res.data;
      });
    },
    //回收商名称下拉
    getAllMachineType() {
      _api
        .listSimpleMerchantView({
          isFilterMatrix: "01",
          isNice: "",
          pageNum: 1,
          pageSize: 999999,
        })
        .then((res) => {
          this.machineType = res.data;
        });
    },
    //查询
    handleConfirm(data,cd) {
      console.log(data);
      let startTime = "";
      let endTime = "";
      if (data.entryTime === null) {
        startTime = "";
        endTime = "";
      } else {
        startTime = Number(moment(data.entryTime[0]).format("x"));
        endTime = Number(moment(data.entryTime[1]).format("x"));
      }
      const baseRequest = {
        imei: data && data.imei ? data.imei : "",
        brandNo: this.brandNo,
        detectionCategoryId: this.detectionCategoryId,
        queryChannel: this.queryChannel,
        merchantId: this.merchantId,
        startTime: startTime,
        endTime: endTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.SeachParams = baseRequest;
      this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  /deep/.el-select-dropdown {
    border-radius: 20px;
  }

  .select_down {
    /deep/.el-popper {
      border-radius: 20px;
    }

    /deep/.el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #e4ecfd;
      color: rgba(9, 129, 255, 1);
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .time-type {
    /deep/.el-input__inner {
      border: none;
    }
  }
}
</style>
